import { BrickElement, defineCustomElement } from '@amedia/brick-template';
import '@amedia/brick-icon';

import { getStyleClasses } from './brick-pill-styles';
import {
  defaultLocale,
  getCountdownTexts,
  getRemaining,
  getRemainingMillis,
  validateDate,
} from './lib/countdownUtils';
import { brickPillTemplate } from './template';
import type { BrickPillData, CountdownData } from './types';
import { mapToColorTheme, mapToToggleVersion, mapToVersion } from './lib/utils';

@defineCustomElement({
  selector: 'brick-pill',
})
export class BrickPill extends BrickElement {
  data: BrickPillData;
  interval: NodeJS.Timeout | null;
  countdowndata: CountdownData;

  constructor(brickPillData: BrickPillData) {
    super();
    this.data = brickPillData;
    this.interval = null;
    this.countdowndata = { timeSro: '', timeTxt: '', dateTime: '' };
  }

  async connectedCallback() {
    this.setData();
    super.connectedCallback();

    if (!this.dataset.locale) this.setAttribute('data-locale', 'nb_NO');

    if (this.dataset.version === 'countdown') {
      if (!this.dataset.countdownDate) return;
      this._start();
    }

    this.addEventListener(
      'brick-teaser:breakingText',
      this._updateBreakingTitle
    );
  }

  static get mirroredProps() {
    return [
      'data-skin',
      'data-version',
      'data-text',
      'data-countdown-date',
      'data-countdown-toggle-version',
      'data-countdown-toggle-text',
      'data-icon-text',
      'data-locale',
    ];
  }

  async disconnectedCallback() {
    super.disconnectedCallback();
    if (this.interval) {
      clearInterval(this.interval);
    }

    this.removeEventListener(
      'brick-teaser:breakingText',
      this._updateBreakingTitle
    );
  }

  setData() {
    this.data = {
      text: this.dataset.text || '',
      version: mapToVersion(this.dataset.version),
      iconText: this.dataset.iconText || '',
      filled: this.dataset.filled === 'true',
      skin: mapToColorTheme(this.dataset.skin),
      locale: this.dataset.locale || defaultLocale,
    };
    this.className = getStyleClasses({
      version: this.data.version,
      filled: this.data.filled,
      skin: this.data.skin,
    });

    if (this.data.version === 'countdown' && this.dataset.countdownDate) {
      const countdownDate = Number(this.dataset.countdownDate);

      const isValidDate = validateDate(countdownDate);
      if (!isValidDate) {
        console.error('No valid date is provided to the countdown');
        return;
      }

      const dateTime = new Date(countdownDate).toISOString();
      this.countdowndata.dateTime = dateTime;

      Object.assign(this.data, {
        countdownDate,
        countdownToggleText: this.dataset.countdownToggleText || '',
        countdownToggleVersion: mapToToggleVersion(
          this.dataset.countdownToggleVersion
        ),
      });
      this._setCountdown();
    }
  }

  private _setCountdown() {
    const countdowndate = this.data.countdownDate || 0;

    const millisRemaining = getRemainingMillis(countdowndate);

    if (0 > millisRemaining) {
      this._onCountdownEnd();
    }

    const { timeTxt, timeSro } = getCountdownTexts(
      millisRemaining,
      this.countdowndata.dateTime,
      this.data.locale
    );

    this.countdowndata = { ...this.countdowndata, timeSro, timeTxt };
  }

  private _toggleCountdown() {
    if (!this.data.countdownToggleVersion) {
      return;
    }
    this.dataset.version = this.data.countdownToggleVersion;
    this.dataset.text = this.data.countdownToggleText;

    this._removeCountdownData();

    this.innerHTML = brickPillTemplate(
      {
        version: this.data.version,
        text: this.data.text,
        filled: this.data.filled,
      },
      false
    );

    if (!this.isRendered) {
      this.isRendered = true;
    }
  }

  private _onCountdownEnd() {
    if (this.data.countdownToggleVersion) {
      this._toggleCountdown();
    } else {
      Object.assign(this.data, {
        countdownDate: null,
      });
      this._removeSelf();
    }
    return;
  }

  private _removeCountdownData() {
    this.parameters.version = this.data.countdownToggleVersion;
    this.parameters.text = this.data.countdownToggleText;
    this.parameters.countdownDate = null;
    this.parameters.countdownToggleText = '';
    this.parameters.countdownToggleVersion = '';
    this.removeAttribute('data-countdown-date');
    this.removeAttribute('data-countdown-toggle-text');
    this.removeAttribute('data-countdown-toggle-version');
    this.countdowndata['timeSro'] = '';
    this.countdowndata['timeTxt'] = '';

    this.setData();
  }

  private _removeSelf() {
    if (this.parentNode) {
      this.parentNode.removeChild(this);
    }
  }

  private _start() {
    this.interval = setInterval(this._update.bind(this), 1000);
  }

  private _stop() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  private _update() {
    if (!this.data.countdownDate) {
      return;
    }

    const millisRemaining = getRemainingMillis(this.data?.countdownDate);

    if (0 > millisRemaining) {
      this._stop();
      this._onCountdownEnd();
      return;
    }

    const timeNode = this.querySelector('time');
    if (!timeNode) {
      return;
    }

    const { timeTxt, timeSro } = getCountdownTexts(
      millisRemaining,
      this.countdowndata.dateTime,
      this.data.locale
    );

    if (timeTxt === this.countdowndata?.timeTxt) {
      return;
    }

    const { days } = getRemaining(millisRemaining);

    if (days > 0) {
      this._stop();
      return;
    }

    const timeElement = timeNode.querySelector('span[aria-hidden=true]');
    if (timeElement) {
      timeElement.innerHTML = timeTxt;
    }

    this._updateSroElement(timeNode, timeSro);
  }

  private _updateSroElement(timeEl: HTMLTimeElement, timeSro: string) {
    const sroElement = timeEl.querySelector('span');

    if (!sroElement) {
      return;
    }

    if (timeSro === this.countdowndata?.timeSro) {
      return;
    }

    sroElement.innerHTML = timeSro;
  }

  get HTML() {
    return brickPillTemplate(this.data, false, this.countdowndata);
  }

  private _updateBreakingTitle(event: CustomEventInit) {
    if (this.data.version !== 'breaking') {
      return;
    }

    const newText = event.detail;

    if (!newText || this.data.text === newText) {
      return;
    }

    const textElement = this.querySelector(`[title]`);

    this.dataset.text = newText;
    this.data.text = newText;

    if (textElement) {
      textElement.setAttribute('title', newText);
      textElement.innerHTML = newText;
    }
  }
}
