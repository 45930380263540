import { BrickElement, defineCustomElement } from '@amedia/brick-template';

import { pillBoxStyle } from './brick-pillbox-styles';
import type { PillboxData } from './types.js';

@defineCustomElement({
  selector: 'brick-pillbox',
})
export class BrickPillBox extends BrickElement {
  pillboxData?: PillboxData;

  constructor(pillboxData: PillboxData) {
    super();
    this.pillboxData = pillboxData;
  }

  async connectedCallback() {
    this.setData();
    this.className = pillBoxStyle({
      filled: this.pillboxData?.filled || false,
    });
    super.connectedCallback();
  }

  setData() {
    this.pillboxData = {
      filled: this.dataset.filled === 'true',
    };
  }

  static get mirroredProps() {
    return ['data-filled'];
  }

  get HTML() {
    return '';
  }
}
